import { apiUrl } from './base';
import { APIRoutes } from '../router/router';
import { makeAuthHeaders } from './fetch/requests';
import {
  BaseType,
  BaseTypeExternal,
  formattedDateToUnixDate,
} from './base-type';

export type HourlyMarginEarned = BaseType & {
  totalNetSumInvoices: number;
  totalBillableAndNonBillableHours: number;
  hourlyMargin: number;
};

export type HourlyMarginsEarned = HourlyMarginEarned[];

export type HourlyMarginsEarnedExternal = BaseTypeExternal & {
  totalNetSumInvoices: number;
  totalBillableAndNonBillableHours: number;
  hourlyMarginRate: number;
};

export function fromAPI(
  externalHourlyMarginsEarned: HourlyMarginsEarnedExternal,
): BaseType {
  return {
    unixDate: formattedDateToUnixDate(
      externalHourlyMarginsEarned.formattedDate,
    ),
    value: externalHourlyMarginsEarned.hourlyMarginRate,
  };
}

export const getHourlyMarginsEarned = async (
  bearerToken: string,
): Promise<HourlyMarginsEarned> => {
  const headers = makeAuthHeaders(bearerToken);
  const requestData = await fetch(
    new Request(`${apiUrl}${APIRoutes.HOURLYMARGINEARNED.create({})}`, {
      headers,
    }),
  );
  const jsonData = await requestData.json();
  return jsonData.map(fromAPI);
};
