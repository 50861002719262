import * as React from 'react';
import { ScaleLoader } from 'react-spinners';
import {
  // Calendar,  (we use our CustomCalendar component)
  CalendarDayData,
} from '@nivo/calendar';
import { DateTime } from 'luxon';
import { CalendarDay, DayWithTargetHours } from '../../data/calendar/types';
import { RequestResult } from '../../data/fetch/result';
import { CustomCalendar } from '../../components/custom-calendar';
import { CalendarTooltipProps } from '../../components/custom-calendar';
import { useTheme } from '@mui/material';

const CustomTooltip = (data: CalendarTooltipProps) => {
  if (data.value === undefined || isNaN(Number(data.value))) {
    return (
      <>
        <div>
          <span
            style={{
              color: data.color,
              backgroundColor: '#282828',
              padding: '10px 5px 10px 10px',
              fontSize: 'inherit',
            }}
          >
            &#x25FC;
          </span>
          <span
            style={{
              color: 'white',
              backgroundColor: '#282828',
              padding: '10px 10px 10px 0px',
              borderRadius: '0px 5px 5px 0px',
            }}
          >
            {data.day}
          </span>
        </div>
      </>
    );
  } else {
    const decimals =
      (data.value + '').split('.')[1] ?? (data.value + '').split('.')[0];
    const target = Number.parseInt(decimals.substring(3, 7)) / 100;
    const worked = Math.abs(
      Number.parseFloat(data.value.substring(0, 6)) + target,
    ).toFixed(2);
    return (
      <>
        <div>
          <span
            style={{
              color: data.color,
              backgroundColor: '#282828',
              padding: '10px 5px 10px 10px',
              fontSize: 'inherit',
            }}
          >
            &#x25FC;
          </span>
          <span
            style={{
              color: 'white',
              backgroundColor: '#282828',
              padding: '10px 10px 10px 0px',
              borderRadius: '0px 5px 5px 0px',
            }}
          >
            {data.day}
            {worked !== 'NaN' && (
              <>
                :<small>worked</small>{' '}
                <strong style={{ color: data.color }}>
                  <big>{worked}</big>
                </strong>
                <small> h of</small>{' '}
                <strong style={{ color: '#73B835' }}>
                  <big>{target}</big>
                </strong>
                <small> h </small>
              </>
            )}
          </span>
        </div>
      </>
    );
  }
};

enum ColorSchemaType {
  workDays = 0,
  main = 1,
}

type ColorSchema = {
  id: ColorSchemaType;
  minValue: number;
  maxValue: number;
  emptyColor: string;
  colors: Array<string>;
  legendItemCount: number;
  tooltip: React.FC<CalendarTooltipProps> | undefined;
};

const colorSchemas: Array<ColorSchema> = [
  {
    // workDays
    id: ColorSchemaType.workDays,
    minValue: -4,
    maxValue: 8,
    emptyColor: '#3778F3',
    colors: ['#E500E5', '#3778F3', '#B8CA2D', '#73B835'],
    legendItemCount: 4,
    tooltip: CustomTooltip,
  },
  {
    // main
    id: ColorSchemaType.main,
    minValue: -2,
    maxValue: 2,
    emptyColor: '#ABABAB',
    colors: ['#849fb0', '#008E83', '#73B835', '#B8CA2D', '#FCD838'],
    legendItemCount: 10,
    tooltip: CustomTooltip,
  },
];

type UserCalendarProps = {
  calendarDataResult:
    | RequestResult<Array<CalendarDay>>
    | RequestResult<Array<DayWithTargetHours>>;
  onSelectDay: (day: DateTime) => void;
  workDays: boolean;
  selectedYear: number;
};

export const UserCalendar = (props: UserCalendarProps) => {
  const { calendarDataResult, onSelectDay, workDays, selectedYear } = props;
  const theme = useTheme();
  switch (calendarDataResult.status) {
    case 'loading': {
      return <ScaleLoader color={theme.palette.primary.main} />;
    }
    case 'loading-with-value':
    case 'success': {
      return (
        <Cal
          onSelectDay={onSelectDay}
          days={calendarDataResult.value}
          workDays={workDays}
          selectedYear={selectedYear}
        />
      );
    }
    default: {
      return null;
    }
  }
};

type CalendarProps = {
  days: Array<CalendarDay> | Array<DayWithTargetHours>;
  onSelectDay: (day: DateTime) => void;
  workDays: boolean;
  selectedYear: number;
};

const Cal: React.FunctionComponent<CalendarProps> = (props) => {
  const { days, onSelectDay, workDays, selectedYear } = props;

  const transformedData = React.useMemo(
    () => transformCalendarData(days, workDays),
    [days, workDays],
  );
  const memoizedCalendarDays = React.useMemo(() => calendarRange(days), [days]);
  const range = workDays
    ? memoizedCalendarDays
    : selectedYearRange(selectedYear);
  return (
    <CustomCalendar
      data={transformedData}
      from={range.startDate.toISODate()}
      to={range.endDate.toISODate()}
      width={1024}
      height={60 + 175 * range.numberOfYears}
      minValue={range.colorSchema.minValue}
      maxValue={range.colorSchema.maxValue}
      emptyColor={range.colorSchema.emptyColor}
      colors={range.colorSchema.colors}
      margin={{ top: -20, right: 40, bottom: -50, left: 40 }}
      yearSpacing={40}
      monthSpacing={10}
      monthBorderColor="#1b2226"
      dayBorderWidth={2}
      dayBorderColor="#1b2226"
      firstDayOfWeek={1}
      isInteractive
      tooltip={range.colorSchema.tooltip}
      onClick={(day: CalendarDayData) => {
        onSelectDay(DateTime.fromJSDate(day.date));
      }}
      theme={{
        textColor: 'white',
        tooltip: {
          container: {
            background: '#333',
          },
        },
      }}
      legends={[
        {
          anchor: 'top',
          direction: 'row',
          translateY: 10,
          translateX: -50,
          itemCount: range.colorSchema.legendItemCount,
          itemWidth: workDays ? 80 : 40,
          itemHeight: 36,
          itemsSpacing: 14,
          itemDirection: 'right-to-left',
        },
      ]}
      legendFormat={(l) => {
        if (workDays) {
          switch (l) {
            case -4:
              return 'Public Holiday';
            case 0:
              return 'Off Day';
            case 4:
              return 'Half Day';
            case 8:
              return 'Full Day';
          }
        }
        return l.toString();
      }}
    />
  );
};

const selectedYearRange = (
  selectedYear: number,
): {
  startDate: DateTime;
  endDate: DateTime;
  numberOfYears: number;
  colorSchema: ColorSchema;
} => {
  const startSelectedYear = DateTime.fromObject({
    year: selectedYear,
    month: 1,
    day: 1,
  });
  const endSelectedYear = DateTime.fromObject({
    year: selectedYear,
    month: 12,
    day: 31,
  });
  return {
    startDate: startSelectedYear,
    endDate: endSelectedYear,
    numberOfYears: 1,
    colorSchema: colorSchemas[ColorSchemaType.main],
  };
};

const calendarRange = (
  days: Array<CalendarDay> | Array<DayWithTargetHours>,
): {
  startDate: DateTime;
  endDate: DateTime;
  numberOfYears: number;
  colorSchema: ColorSchema;
} => {
  const dateTimes = (days as any).map((day: any) => day.date);
  const startDate = DateTime.min.apply(null, dateTimes);
  const endDate = DateTime.max.apply(null, dateTimes);
  return {
    startDate,
    endDate,
    numberOfYears: endDate.year - startDate.year + 1,
    colorSchema: colorSchemas[ColorSchemaType.workDays],
  };
};

const transformCalendarDay = (
  day: any,
  workDays: boolean,
): { day: string; value: number } => {
  if (!workDays) {
    const extraData =
      Number.parseFloat(day.targetHours.toFixed(2)) / 100000 + 0.0000000001;
    var value = Number.parseFloat(
      (day.workedHours - day.targetHours).toFixed(2),
    );
    value = value < 0 ? value - extraData : value + extraData;
    return {
      day: day.date.toISODate(),
      value: value,
    };
  } else {
    if (day.dayType === 2) {
      return {
        day: day.date.toISODate(),
        value: -4,
      };
    }
    return {
      day: day.date.toISODate(),
      value: day.targetHours,
    };
  }
};

const transformCalendarData = (
  data: Array<CalendarDay> | Array<DayWithTargetHours>,
  workDays: boolean,
): Array<{ day: string; value: number }> => {
  if (!workDays) {
    return (data as any)
      .filter(
        (day: CalendarDay) => day.targetHours !== 0 || day.workedHours !== 0,
      )
      .map((day: CalendarDay) => transformCalendarDay(day, workDays));
  } else {
    return (data as any).map((day: DayWithTargetHours) =>
      transformCalendarDay(day, workDays),
    );
  }
};
