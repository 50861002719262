import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { MainRoutes } from '../../router/router';

interface WithAuthProps {
  children?: React.ReactNode;
}

export const WithAuth: React.FunctionComponent<WithAuthProps> = ({
  children,
}) => {
  return (
    <>
      <UnauthenticatedTemplate>
        Please login first
        <Link to={MainRoutes.LOGIN.create({})}>Login</Link>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
    </>
  );
};
