import { apiUrl } from './base';
import { APIRoutes } from '../router/router';
import { makeAuthHeaders } from './fetch/requests';
import {
  BaseType,
  BaseTypeExternal,
  formattedDateToUnixDate,
} from './base-type';

export type UtilisationLevel = BaseType & {
  totalHours: number;
  billableAndNonBillableHours: number;
  ratio: number;
};

export type UtilisationLevels = UtilisationLevel[];

export type UtilisationLevelExternal = BaseTypeExternal & {
  totalHours: number;
  billableAndNonBillableHours: number;
  ratio: number;
};

export function fromAPI(
  externalUtilisationLevel: UtilisationLevelExternal,
): BaseType {
  return {
    unixDate: formattedDateToUnixDate(externalUtilisationLevel.formattedDate),
    value: externalUtilisationLevel.ratio,
  };
}

export const getUtilisationLevels = async (
  bearerToken: string,
): Promise<UtilisationLevels> => {
  const headers = makeAuthHeaders(bearerToken);
  const requestData = await fetch(
    new Request(`${apiUrl}${APIRoutes.UTILISATION.create({})}`, { headers }),
  );
  const jsonData = await requestData.json();
  return jsonData.map(fromAPI);
};
