import { apiUrl } from './base';
import { APIRoutes } from '../router/router';
import { makeAuthHeaders } from './fetch/requests';
import {
  BaseType,
  BaseTypeExternal,
  formattedDateToUnixDate,
} from './base-type';

export type Invoices = BaseType[];

export type InvoiceExternal = BaseTypeExternal & {
  totalNetSumInvoice: number;
};

export function fromAPI(externalInvoice: InvoiceExternal): BaseType {
  return {
    unixDate: formattedDateToUnixDate(externalInvoice.formattedDate),
    value: externalInvoice.totalNetSumInvoice,
  };
}

export const getInvoices = async (bearerToken: string): Promise<Invoices> => {
  const headers = makeAuthHeaders(bearerToken);
  const requestData = await fetch(
    new Request(`${apiUrl}${APIRoutes.INVOICE.create({})}`, { headers }),
  );
  const jsonData = await requestData.json();
  return jsonData.map(fromAPI);
};
