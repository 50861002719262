import * as React from 'react';
import { BarTooltipProps, ResponsiveBar } from '@nivo/bar';
import { DateRange } from '../chart/chart';
import { getProject, Project } from '../../data/project';
import { useAccount, useMsal } from '@azure/msal-react';
import { authRequest } from '../../data/auth';
import { ScaleLoader } from 'react-spinners';
import './projects-info.scss';
import { DateTime } from 'luxon';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { ResponsivePie } from '@nivo/pie';
import YearPicker from '../../components/yearPicker/year-picker';
import { Container, useTheme } from '@mui/material';
import { RequestResult } from '../../data/fetch/result';

const ProjectsInfo = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [projectInfo, setProjectInfo] = React.useState<
    RequestResult<Project[]>
  >({
    status: 'loading',
  });
  const [unselectedKeys, setUnselectedKeys] = React.useState<string[]>([]);
  const currentYear = new Date().getFullYear();
  const startYear = 2017;
  const fromDate = new Date(`${currentYear}-01-01`);
  const toDate = new Date(`${currentYear}-12-31`);
  const [selectedDateRange, setSelectedDateRange] = React.useState<DateRange>({
    from: fromDate.valueOf(),
    to: toDate.valueOf(),
  });
  const [selectedYear, setSelectedYear] = React.useState<number>(
    DateTime.now().year,
  );

  React.useEffect(() => {
    if (
      (projectInfo.status === 'loading' ||
        projectInfo.status === 'loading-with-value') &&
      account
    ) {
      instance
        .acquireTokenSilent({
          scopes: authRequest.scopes,
          account,
        })
        .then(async (response) => {
          var tempProjectsInfo = await getProject(
            response.accessToken,
            DateTime.fromMillis(selectedDateRange.from),
            DateTime.fromMillis(selectedDateRange.to),
          );
          tempProjectsInfo.sort((a, b) =>
            a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? -1 : 1,
          );
          setProjectInfo({
            status: 'success',
            value: tempProjectsInfo,
          });
        })
        .catch((e) => {
          setProjectInfo({
            status: 'error',
            errorValue: e,
          });
        });
    }
  }, [projectInfo, account, instance, selectedDateRange]);

  const formatTooltip = (value: string) => {
    switch (value) {
      case 'internalHours':
        return `Internal Hours`;
      case 'billableHours':
        return `Billable Hours`;
      case 'nonBillableHours':
        return `Non Billable Hours`;
      case 'vacationHours':
        return 'Vacation Hours';
      default:
        return value;
    }
  };

  const info = [
    { id: 'internalHours', label: 'Internal', color: '#dd600c' },
    { id: 'billableHours', label: 'Billable', color: '#d82f67' },
    { id: 'nonBillableHours', label: 'Non Billable', color: '#87418a' },
    { id: 'vacationHours', label: 'Vacations', color: '#6a4f90' },
  ];
  //finds the max value of the data summing the billable, non billable, vacations and internal hours
  const tooltipCap =
    projectInfo.status === 'success' || projectInfo.status === 'loading-with-value'
      ? projectInfo.value
          .map((e: Project) => {
            return (
              e.billableHours +
              e.nonBillableHours +
              e.vacationHours +
              e.internalHours
            );
          })
          .reduce((a: number, b: number) => Math.max(a, b), 0)/50
      : 50;
  const yearsRange: number[] = [];
  for (var num = startYear; num <= currentYear; num++) {
    yearsRange.push(num);
  }
  const theme = useTheme();

  if (
    projectInfo.status === 'success' ||
    projectInfo.status === 'loading-with-value'
  ) {
    return (
      <Container maxWidth="xl">
        <div
          style={{ height: `${5 + projectInfo.value.length * 1.5}em` }}
          className="project-chart-container"
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <h2 className="projects-title">Projects</h2>
            <div className="project-date-filter">
              <YearPicker
                value={selectedYear}
                handleChange={(year) => {
                  setSelectedYear(year);
                  setSelectedDateRange({
                    ...selectedDateRange,
                    from: new Date(`${year}-01-01`).valueOf(),
                    to: new Date(`${year}-12-31`).valueOf(),
                  });
                  setProjectInfo({
                    status: 'loading-with-value',
                    value: projectInfo.value,
                  });
                }}
                dates={yearsRange}
              />
              <form noValidate>
                <TextField
                  id="date"
                  label="Date From"
                  type="date"
                  className={'project-date-picker'}
                  variant="standard"
                  value={DateTime.fromMillis(
                    selectedDateRange.from,
                  ).toISODate()}
                  onChange={(date) => {
                    if (date != null) {
                      setSelectedDateRange({
                        ...selectedDateRange,
                        from: new Date(date.target.value).valueOf(),
                      });
                      setProjectInfo({
                        status: 'loading-with-value',
                        value: projectInfo.value,
                      });
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </form>
              <form noValidate>
                <TextField
                  id="date"
                  label="Date To"
                  type="date"
                  className={'project-date-picker'}
                  variant="standard"
                  value={DateTime.fromMillis(selectedDateRange.to).toISODate()}
                  onChange={(date) => {
                    if (date != null) {
                      setSelectedDateRange({
                        ...selectedDateRange,
                        to: new Date(date.target.value).valueOf(),
                      });
                      setProjectInfo({
                        status: 'loading-with-value',
                        value: projectInfo.value,
                      });
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </form>
            </div>
          </Grid>
          {projectInfo.status !== 'success' &&
          projectInfo.status !== 'loading-with-value' ? (
            <ScaleLoader
              color={theme.palette.primary.main}
              height="100px"
              width="20px"
            />
          ) : projectInfo.value && projectInfo.value.length > 0 ? (
            <>
              <ResponsiveBar
                data={projectInfo.value}
                keys={info
                  .filter((e) => !unselectedKeys.includes(e.id))
                  .map((e) => e.id)}
                indexBy={'name'}
                margin={{ top: 70, right: 20, left: 220 }}
                padding={0.3}
                layout="horizontal"
                colors={info
                  .filter((e) => !unselectedKeys.includes(e.id))
                  .map((e) => e.color)}
                labelTextColor="white"
                tooltip={({ id, value, color }: BarTooltipProps<Project>) => (
                  <div style={{ backgroundColor: 'white', padding: '5px' }}>
                    <strong style={{ color }}>
                      {formatTooltip(String(id))} :{' '}
                      {`${value.toFixed(2)} Hours`}
                    </strong>
                  </div>
                )}
                axisBottom={{
                  legend: 'Hours',
                  legendPosition: 'middle',
                  legendOffset: 40,
                }}
                valueFormat={(value: number) =>
                  value >= tooltipCap ? `${value.toFixed(2)}` : ' '
                }
                theme={{
                  textColor: 'white',
                  tooltip: {
                    container: {
                      color: 'FFFFFF',
                      backgroundColor: 'ff00ff',
                    },
                  },
                  axis: {
                    legend: {
                      text: {
                        fill: 'white',
                      },
                    },
                    ticks: {
                      text: {
                        fill: 'white',
                      },
                    },
                  },
                }}
                legends={[
                  {
                    dataFrom: 'keys',
                    data: info.map((e) => ({
                      color: !unselectedKeys.includes(e.id) ? e.color : 'gray',
                      id: e.id,
                      label: e.label,
                    })),
                    anchor: 'top-left',
                    direction: 'row',
                    itemWidth: 100,
                    itemHeight: 20,
                    toggleSerie: false,
                    justify: false,
                    translateX: 0,
                    translateY: -30,
                    itemsSpacing: 2,
                    symbolSize: 20,
                    itemDirection: 'left-to-right',
                    onClick: (datum) => {
                      if (unselectedKeys.includes(datum.id.toString())) {
                        const updatedArray = unselectedKeys.filter(
                          (item) => item !== datum.id.toString(),
                        );
                        setUnselectedKeys(updatedArray);
                      } else {
                        setUnselectedKeys([
                          ...unselectedKeys,
                          datum.id.toString(),
                        ]);
                      }
                    },
                  },
                ]}
              />
              <div
                style={{
                  margin: 'auto',
                  height: '600px',
                  width: '600px',
                }}
              >
                <ResponsivePie
                  data={info.map((key, index): any => {
                    return {
                      id: key.label,
                      label: key.label,
                      value: projectInfo.value.reduce(
                        (acc: number, cur) =>
                          acc + Number(cur[key.id as keyof typeof cur]),
                        0,
                      ),
                      color: [info[index].color],
                    };
                  })}
                  colors={info.map((e) => e.color)}
                  margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                  innerRadius={0.5}
                  padAngle={0.7}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="white"
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor="white"
                  valueFormat={(value) => `${Number(value).toFixed(2)}`}
                />
              </div>
            </>
          ) : (
            <h3 className="error-message">No data</h3>
          )}{' '}
        </div>
      </Container>
    );
  } else {
    return (
      <div style={{ marginTop: '60px' }}>
        <ScaleLoader
          color={theme.palette.primary.main}
          height="100px"
          width="20px"
        />
      </div>
    );
  }
};
export default ProjectsInfo;
