import * as React from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { authRequest } from '../../data/auth';
import { Navigate } from 'react-router-dom';
import { MainRoutes } from '../../router/router';

export const Login: React.FunctionComponent = () => {
  const { login, error } = useMsalAuthentication(
    InteractionType.Silent,
    authRequest,
  );
  React.useEffect(() => {
    if (error) {
      login(InteractionType.Redirect, authRequest);
    }
  }, [error, login]);
  return (
    <>
      <AuthenticatedTemplate>
        <Navigate replace to={MainRoutes.HOME.create({})} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>Logging you in ...</UnauthenticatedTemplate>
    </>
  );
};
