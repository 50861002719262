import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { MainRoutes } from './router/router';
import { authProvider } from './data/auth';
import { Overview } from './views/overview';
import { Login } from './views/auth/login';
import { Logout } from './views/auth/logout';
import { UserTimes } from './views/user-times/user-times';
import { WithAuth } from './views/auth/with-auth';
import { Projects } from './views/projects/projects';
import {
  CssBaseline,
  PaletteMode,
  ThemeProvider,
  createTheme,
  getContrastRatio,
} from '@mui/material';
import { ColorModeContext } from './context';

const getDesignTokens = (mode: PaletteMode) => {
  const selectedColor =
    mode === 'light'
      ? '#c83771'
      : (localStorage.getItem('userColor') as string) ?? '#0052cc';
  let theme = createTheme({
    // Theme customization goes here as usual, including tonalOffset and/or
    // contrastThreshold as the augmentColor() function relies on these
    palette: {
      primary: {
        main: selectedColor,
        contrastText:
          getContrastRatio(selectedColor, '#fff') > 4.5 ? '#fff' : '#111',
      },
      background: {
        default:
          getContrastRatio(selectedColor, '#fff') > 4.5 ? '#1b2226' : '#787878',
      },
    },
  });

  return theme;
};

function randomColor() {
  let hex = Math.floor(Math.random() * 0xffffff);
  let color = '#' + hex.toString(16);

  return color;
}
export const App = (): JSX.Element => {
  const savedColor = localStorage.getItem('color');
  const [mode, setMode] = React.useState<PaletteMode>(
    (savedColor as PaletteMode) ?? 'dark',
  );
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) => {
          const result = prevMode === 'light' ? 'dark' : 'light';
          localStorage.setItem('color', result);
          localStorage.setItem('userColor', randomColor());
          return result;
        });
      },
    }),
    [],
  );

  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  return (
    <MsalProvider instance={authProvider}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              <Route path={MainRoutes.LOGIN.template()} element={<Login />} />
              <Route path={MainRoutes.LOGOUT.template()} element={<Logout />} />
              <Route
                path={MainRoutes.PROJECTS.template()}
                element={
                  <WithAuth>
                    <Projects />
                  </WithAuth>
                }
              ></Route>
              <Route
                path={MainRoutes.USER_TIMES.template()}
                element={
                  <WithAuth>
                    <UserTimes />
                  </WithAuth>
                }
              ></Route>
              <Route
                path={MainRoutes.HOME.template()}
                element={
                  <WithAuth>
                    <Overview />
                  </WithAuth>
                }
              ></Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </MsalProvider>
  );
};
