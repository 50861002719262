import { DateTime } from 'luxon';
import { BaseTypeExternal } from '../base-type';
import { Conversion } from '../fetch/conversion';
import { CalendarDay, DayTypeEnum, DayWithTargetHours } from './types';

/**
 * Data as we get it from the API.
 */
type ApiData = BaseTypeExternal & {
  userName: string;
  targetHours: number;
  workedHours: number;
  overTime: number;
};
type WorkDaysApiData = {
  workdayDate: string;
  targetHours: number;
  description: string;
  dayType: DayTypeEnum;
};

export const calendarDayFromApi = (
  data: unknown,
): Conversion<Array<CalendarDay>> => {
  const days: Array<CalendarDay> = (data as Array<ApiData>).map((val) => {
    const date = DateTime.fromISO(val.formattedDate);
    return {
      date,
      userName: val.userName,
      targetHours: val.targetHours,
      workedHours: val.workedHours,
      overTime: val.overTime,
    };
  });
  return { errorType: 'none', value: days };
};
export const workDayFromApi = (
  data: unknown,
): Conversion<Array<DayWithTargetHours>> => {
  const days: Array<DayWithTargetHours> = (data as Array<WorkDaysApiData>).map(
    (val) => {
      const date = DateTime.fromISO(val.workdayDate);
      return {
        date,
        targetHours: val.targetHours,
        description: val.description,
        dayType: val.dayType,
      };
    },
  );
  return { errorType: 'none', value: days };
};
