import { Bar, BarDatum, ComputedDatum } from '@nivo/bar';
import { ScaleLoader } from 'react-spinners';
import { useUserCalendar, useUserWorkDays } from '../../data/calendar/hooks';
import { DayWithTargetHours, SelectedRange } from '../../data/calendar/types';
import './user-times.scss';
import { useEffect } from 'react';
import React from 'react';
import { useTheme } from '@mui/material';

type SelectedCalendarRangeProps = {
  userId: string;
  selectedRange: SelectedRange;
  workHours: boolean;
};

type ChartData = {
  date: string;
  workedTime?: number;
  overTime?: number;
  missingTime?: number;
  dummyTime?: number;
};

export const SelectedCalendarRange = (props: SelectedCalendarRangeProps) => {
  const { userId, selectedRange, workHours } = props;
  const theme = useTheme();
  const { userCalendar, setUserCalendar } = useUserCalendar(
    userId,
    'Daily',
    selectedRange.week.start,
    selectedRange.week.end,
  );
  useEffect(() => {
    setUserCalendar({
      status: 'loading-with-value',
      value: [],
    });
  }, [selectedRange, setUserCalendar]);
  const calendarRangeResult = useUserWorkDays(userId);
  const getDayType = (day: DayWithTargetHours) => {
    switch (day.dayType) {
      case 0: {
        if (day.targetHours === 0) {
          return 'Weekday';
        }
        return 'Workday';
      }
      case 1: {
        return 'Weekend';
      }
      case 2: {
        return 'Public Holiday';
      }
      default: {
        return 'Workday';
      }
    }
  };
  const rowColors = ['#FAFAFA', '#3778F3', '#E500E5'];
  if (!workHours) {
    switch (userCalendar.status) {
      case 'loading': {
        return <ScaleLoader color={theme.palette.primary.main} />;
      }
      case 'error': {
        return <>Error while loading range</>;
      }
      case 'loading-with-value':
      case 'success': {
        const calendarRange = userCalendar.value;

        const { workedHours, targetHours, weeklyOverTime } =
          calendarRange.reduce(
            (acc, day) => {
              return {
                workedHours: acc.workedHours + day.workedHours,
                targetHours: acc.targetHours + day.targetHours,
                weeklyOverTime: acc.weeklyOverTime + day.overTime,
              };
            },
            {
              workedHours: 0,
              targetHours: 0,
              weeklyOverTime: 0,
            },
          );

        const selectedDay = calendarRange.find((day) =>
          day.date.equals(selectedRange.selectedDay),
        );

        const mchartData: Array<ChartData> = [];
        let date = selectedRange.week.start;
        let maxValue: number | 'auto' = 8;
        while (mchartData.length < 7) {
          const currentDate = date;
          let chartDay: ChartData = {
            date: currentDate.toISODate(),
          };
          const day = calendarRange.find((day) => day.date.equals(currentDate));

          if (day !== undefined) {
            maxValue = 'auto';
            if (day.targetHours > 0) {
              if (day.overTime === 0) {
                chartDay.workedTime = day.workedHours;
              } else if (day.overTime > 0) {
                chartDay.workedTime = day.targetHours;
                chartDay.overTime = day.overTime;
              } else {
                chartDay.workedTime = day!.workedHours;
                chartDay.missingTime = -day!.overTime;
              }
            } else {
              if (day.workedHours > 0) {
                chartDay.overTime = day.workedHours;
              }
            }
          }
          mchartData.push(chartDay);
          date = date.plus({ day: 1 });
        }
        return (
          <div style={{minHeight: "300px"}}>
            <h2>Weekly View KW{selectedRange.selectedDay.weekNumber}</h2>

            <div>
              <Bar
                width={1024}
                height={300}
                margin={{ top: 60, right: 40, bottom: 60, left: 40 }}
                data={mchartData}
                indexBy="date"
                maxValue={maxValue}
                keys={['workedTime', 'overTime', 'missingTime']}
                markers={[
                  {
                    axis: 'y',
                    value: 8,
                    lineStyle: { stroke: 'white', strokeWidth: 3 },
                  },
                ]}
                colors={barColor}
                theme={{
                  textColor: 'white',
                  tooltip: {
                    container: {
                      background: '#333',
                    },
                  },
                }}
              />
            </div>

            <div className="user-times-selected">
              <div className="user-times-selected-day">
                <h2>Daily View {selectedRange.selectedDay.toISODate()}</h2>
                <table>
                  <tbody>
                    <tr>
                      <th>Worked Hours</th>
                      <td>{selectedDay?.workedHours}h</td>
                    </tr>
                    <tr>
                      <th>Target Hours</th>
                      <td>{selectedDay?.targetHours}h</td>
                    </tr>
                    <tr>
                      <th>Daily Overtime</th>
                      <td>{selectedDay?.overTime}h</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="user-times-selected-week">
                <h2>Weekly Summary</h2>
                <table>
                  <tbody>
                    <tr>
                      <th>Worked Hours</th>
                      <td>{workedHours}h</td>
                    </tr>
                    <tr>
                      <th>Target Hours</th>
                      <td>{targetHours}h</td>
                    </tr>
                    <tr>
                      <th>Weekly Overtime</th>
                      <td>{weeklyOverTime}h</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        );
      }
    }
  } else {
    const filterByWeek = (day: DayWithTargetHours) => {
      if (
        day.date.month === selectedRange.selectedDay.month &&
        day.date.year === selectedRange.selectedDay.year
      )
        return true;
      else return false;
    };
    switch (calendarRangeResult.status) {
      case 'loading': {
        return <ScaleLoader color={theme.palette.primary.main} />;
      }
      case 'error': {
        return <>Error while loading range</>;
      }
      case 'loading-with-value':
      case 'success': {
        const calendarRange = calendarRangeResult.value;
        const weekData = calendarRange.filter(filterByWeek);
        return (
          <div>
            <h2 className="calendar-range-align">
              Monthly View for {selectedRange.selectedDay.monthLong}
            </h2>
            <table className="calendar-range-align">
              <tbody style={{ backgroundColor: '#1b2226' }}>
                {weekData.map((day) => {
                  return (
                    <tr
                      style={{
                        color:
                          day.targetHours === 0 && day.dayType === 0
                            ? '#3778F3'
                            : rowColors[day.dayType],
                      }}
                    >
                      <th>{day.date.toLocaleString()}</th>
                      <th>{getDayType(day)}</th>
                      <th>{day.description}</th>
                      <th>{`${day.targetHours}h`}</th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div style={{ height: '10vh' }}> </div>
          </div>
        );
      }
    }
  }
};

const barColor = ({ id }: ComputedDatum<BarDatum>) => {
  switch (id) {
    case 'workedTime': {
      return '#73B835';
    }
    case 'overTime': {
      return '#FCD838';
    }
    default: {
      return '#2F4858';
    }
  }
};
