import * as React from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { MainRoutes } from '../../router/router';
import { Link } from 'react-router-dom';

export const Logout: React.FunctionComponent = () => {
  const { instance } = useMsal();

  React.useEffect(() => {
    instance.logout();
  });

  return (
    <>
      <AuthenticatedTemplate>Logging out...</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        Please login first
        <Link to={MainRoutes.LOGIN.create({})}>Login</Link>
      </UnauthenticatedTemplate>
    </>
  );
};
