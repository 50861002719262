import React, { memo } from 'react';
import { CalendarYearLegendsProps } from './types';

export const CalendarYearLegends = memo(
  ({ years, legend, theme }: CalendarYearLegendsProps) => {
    return (
      <>
        {years.map((year) => {
          return (
            <text
              key={year.year}
              transform={`translate(${year.x},${year.y}) rotate(${year.rotation})`}
              textAnchor="middle"
              style={theme.labels.text}
            >
              {legend(year.year)}
            </text>
          );
        })}
      </>
    );
  },
);
