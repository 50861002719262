import * as React from 'react';
import { DateTime } from 'luxon';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './quarter-picker.scss';
type QuarterPickerProps = {
  value: number;
  handleChange: (value: number) => void;
  text: string;
  dates: number[];
};

const QuarterPicker = ({
  value,
  handleChange,
  text,
  dates,
}: QuarterPickerProps) => (
  <div>
    <FormControl variant="outlined">
      <Select
        className="quarter-picker"
        native
        value={value}
        onChange={(event) => handleChange(Number(event.target.value))}
        label={text}
      >
        {dates.map((date: number) => (
          <option
            key={date.toString()}
            style={{ backgroundColor: '#393d41' }}
            value={date}
          >
            {DateTime.fromMillis(date).toFormat("yyyy'-Q'q")}
          </option>
        ))}
      </Select>
    </FormControl>
  </div>
);

export default QuarterPicker;
