import * as React from 'react';

import { Chart, ChartData, DateRangeAsync } from '../components';
import { getInvoices } from '../data/invoice';
import { getAverageHourlyRatesCharged } from '../data/average-hourly-rate-charged';
import { getAverageHourlyRatesEarned } from '../data/average-hourly-rate-earned';
import { getUtilisationLevels } from '../data/utilisation-level';
import { getHourlyMarginsCharged } from '../data/hourly-margin-charged';
import { FailedTimesheet, getFailedTimesheet } from '../data/failed-timesheets';
import { getHourlyMarginsEarned } from '../data/hourly-margin-earned';
import { Users } from '../views/users/users';
import './overview.scss';
import '../styles/general.scss';
import { useAccount, useMsal } from '@azure/msal-react';
import { authRequest } from '../data/auth';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import QuarterPicker from '../components/quarterPicker/quarter-picker';
import { BaseType } from '../data/base-type';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Container, Tooltip, Typography, useTheme } from '@mui/material';
import { NavigationBar } from '../components/navigation/NavigationBar';
import { useMemo } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type ChartState = ChartData<BaseType>;
type FailedTimesheetState = ChartData<FailedTimesheet>;

export const Overview = (): JSX.Element => {
  const theme = useTheme();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);

  const [failedTimesheetsState, setFailedTimesheetsState] =
    React.useState<FailedTimesheetState>({
      isLoading: true,
    });

  const [invoiceState, setInvoiceState] = React.useState<ChartState>({
    isLoading: true,
  });

  const [averageHourlyRateChargedState, setAverageHourlyRateChargedState] =
    React.useState<ChartState>({
      isLoading: true,
    });

  const [averageHourlyRateEarnedState, setAverageHourlyRateEarnedState] =
    React.useState<ChartState>({
      isLoading: true,
    });

  const [utilisationLevelState, setUtilisationLevelState] =
    React.useState<ChartState>({
      isLoading: true,
    });

  const [hourlyMarginsChargedState, setHourlyMarginsChargedState] =
    React.useState<ChartState>({
      isLoading: true,
    });

  const [hourlyMarginsEarnedState, setHourlyMarginsEarnedState] =
    React.useState<ChartState>({
      isLoading: true,
    });

  const pickerDates = useMemo(() => {
    if (invoiceState.isLoading) {
      return [];
    }
    return invoiceState.data
      .map((invoice) => invoice.unixDate)
      .filter((value, index, array) => array.indexOf(value) === index);
  }, [invoiceState]);

  const [selectedDateRange, setSelectedDateRange] =
    React.useState<DateRangeAsync>({
      isLoading: true,
    });

  React.useEffect(() => {
    if (selectedDateRange.isLoading && pickerDates.length > 0) {
      setSelectedDateRange({
        isLoading: false,
        range: {
          from:
            pickerDates.length < 8
              ? pickerDates[0]
              : pickerDates[pickerDates.length - 8],
          to: pickerDates[pickerDates.length - 1],
        },
      });
    }
  }, [invoiceState, pickerDates, selectedDateRange]);

  React.useEffect(() => {
    if (failedTimesheetsState.isLoading && account) {
      instance
        .acquireTokenSilent({
          scopes: authRequest.scopes,
          account,
        })
        .then((response) => {
          getFailedTimesheet(response.accessToken).then((failedTimesheets) => {
            setFailedTimesheetsState({
              isLoading: false,
              data: failedTimesheets,
            });
          });
        });
    }
  }, [selectedDateRange, failedTimesheetsState, account, instance]);
  React.useEffect(() => {
    if (invoiceState.isLoading && account) {
      instance
        .acquireTokenSilent({
          scopes: authRequest.scopes,
          account,
        })
        .then((response) => {
          getInvoices(response.accessToken).then((invoices) => {
            if (
              invoices[invoices.length - 1].unixDate <
              getCurrentQuartalStartMillis()
            ) {
              invoices.push({
                unixDate: getCurrentQuartalStartMillis(),
                value: 0,
              });
            }
            setInvoiceState({ isLoading: false, data: invoices });
          });
        });
    }
  }, [account, instance, invoiceState]);
  React.useEffect(() => {
    if (averageHourlyRateChargedState.isLoading && account) {
      instance
        .acquireTokenSilent({
          scopes: authRequest.scopes,
          account,
        })
        .then((response) => {
          getAverageHourlyRatesCharged(response.accessToken).then(
            (averageHourlyRatesCharged) => {
              setAverageHourlyRateChargedState({
                isLoading: false,
                data: averageHourlyRatesCharged,
              });
            },
          );
        });
    }
  }, [account, instance, averageHourlyRateChargedState]);
  React.useEffect(() => {
    if (averageHourlyRateEarnedState.isLoading && account) {
      instance
        .acquireTokenSilent({
          scopes: authRequest.scopes,
          account,
        })
        .then((response) => {
          getAverageHourlyRatesEarned(response.accessToken).then(
            (averageHourlyRatesEarned) => {
              setAverageHourlyRateEarnedState({
                isLoading: false,
                data: averageHourlyRatesEarned,
              });
            },
          );
        });
    }
  }, [account, instance, averageHourlyRateEarnedState]);
  React.useEffect(() => {
    if (utilisationLevelState.isLoading && account) {
      instance
        .acquireTokenSilent({
          scopes: authRequest.scopes,
          account,
        })
        .then((response) => {
          getUtilisationLevels(response.accessToken).then(
            (utilisationLevels) => {
              setUtilisationLevelState({
                isLoading: false,
                data: utilisationLevels,
              });
            },
          );
        });
    }
  }, [account, instance, utilisationLevelState]);
  React.useEffect(() => {
    if (hourlyMarginsChargedState.isLoading && account) {
      instance
        .acquireTokenSilent({
          scopes: authRequest.scopes,
          account,
        })
        .then((response) => {
          getHourlyMarginsCharged(response.accessToken).then(
            (hourlyMarginsCharged) => {
              setHourlyMarginsChargedState({
                isLoading: false,
                data: hourlyMarginsCharged,
              });
            },
          );
        });
    }
  }, [account, instance, hourlyMarginsChargedState]);
  React.useEffect(() => {
    if (hourlyMarginsEarnedState.isLoading && account) {
      instance
        .acquireTokenSilent({
          scopes: authRequest.scopes,
          account,
        })
        .then((response) => {
          getHourlyMarginsEarned(response.accessToken).then(
            (hourlyMarginsEarned) => {
              setHourlyMarginsEarnedState({
                isLoading: false,
                data: hourlyMarginsEarned,
              });
            },
          );
        });
    }
  }, [account, instance, hourlyMarginsEarnedState]);

  const getCurrentQuartalStartMillis = (): number => {
    const today = new Date();
    const year = today.getFullYear();
    const currentQuarter = Math.floor(today.getMonth() / 3);
    const quarterStart = new Date(year, currentQuarter * 3, 1);
    return quarterStart.getTime();
  };

  return (
    <div className="app">
      <header className="app-header">
        <NavigationBar />
      </header>
      <Container maxWidth="xl">
        <div>
          <div className="employees-title-container">
            <h2 className="employees-title">Employees</h2>
            <Tooltip
              className="tooltip-info"
              title="Green bar means more than 90% of target hours are booked in the previous 7 days, starting yesterday. Red is less than 90%, while grey means tracking deactivated."
            >
              <InfoOutlinedIcon style={{ fill: theme.palette.primary.main }} />
            </Tooltip>
          </div>
          <Users />
        </div>
        {!failedTimesheetsState.isLoading &&
          failedTimesheetsState.data.length > 0 && (
            <Stack
              sx={{ width: '500px', margin: 'auto', marginBottom: '40px' }}
              spacing={1}
            >
              {failedTimesheetsState.data.map(
                (failedTimesheet: FailedTimesheet, index) => {
                  return (
                    <Alert
                      key={index}
                      variant="filled"
                      severity="warning"
                      sx={{ background: '#FCD838', color: 'black' }}
                      action={
                        <Button
                          sx={{ background: '#FCD838', color: 'black' }}
                          size="small"
                          target="_blank"
                          variant="text"
                          href={`https://office.bexio.com/index.php/monitoring/show/id/${failedTimesheet.id}`}
                        >
                          <strong>Show</strong>
                        </Button>
                      }
                    >
                      Wrong entry for {failedTimesheet.userName} on{' '}
                      {new Date(failedTimesheet.date).toDateString()}!
                    </Alert>
                  );
                },
              )}
            </Stack>
          )}
        <Grid
          className="filter-grid"
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <h2 className="kpi-title">KPI&apos;s</h2>
          <div className="filters">
            <h4 className="filters-title">Filters</h4>
            {!selectedDateRange.isLoading && (
              <div className="filters-date">
                <QuarterPicker
                  value={selectedDateRange.range.from}
                  handleChange={(date) => {
                    setSelectedDateRange({
                      ...selectedDateRange,
                      range: { ...selectedDateRange.range, from: date },
                    });
                  }}
                  text="Date From"
                  dates={pickerDates}
                />
                <QuarterPicker
                  value={selectedDateRange.range.to}
                  handleChange={(date) => {
                    setSelectedDateRange({
                      ...selectedDateRange,
                      range: { ...selectedDateRange.range, to: date },
                    });
                  }}
                  text="Date To"
                  dates={pickerDates}
                />
              </div>
            )}
          </div>
        </Grid>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid container item xs={12} sm={6}>
            <Chart
              chartData={invoiceState}
              title="Invoices"
              bottomLegend="Time [quarter]"
              leftLegend="Invoices [kCHF]"
              selectedDateRange={selectedDateRange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Chart
              chartData={utilisationLevelState}
              title="Average Utilisation Level"
              bottomLegend="Time [quarter]"
              leftLegend="Average Utilisation Level [%]"
              selectedDateRange={selectedDateRange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Chart
              chartData={averageHourlyRateChargedState}
              title="Average Hourly Rate Charged"
              bottomLegend="Time [quarter]"
              leftLegend="Average Hourly Rate Charged [CHF/h]"
              selectedDateRange={selectedDateRange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Chart
              chartData={averageHourlyRateEarnedState}
              title="Average Hourly Rate Earned"
              bottomLegend="Time [quarter]"
              leftLegend="Average Hourly Rate Earned [CHF/h]"
              selectedDateRange={selectedDateRange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Chart
              chartData={hourlyMarginsChargedState}
              title="Hourly Margin Charged"
              bottomLegend="Time [quarter]"
              leftLegend="Hourly Margin Charged [CHF/h]"
              selectedDateRange={selectedDateRange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Chart
              chartData={hourlyMarginsEarnedState}
              title="Hourly Margin Earned"
              bottomLegend="Time [quarter]"
              leftLegend="Hourly Margin Earned [CHF/h]"
              selectedDateRange={selectedDateRange}
            />
          </Grid>
        </Grid>
      </Container>
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        sx={{ color: 'white' }}
      >
        {process.env.REACT_APP_EB_VERSION}
      </Typography>
    </div>
  );
};
