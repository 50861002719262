import {
  Configuration,
  PublicClientApplication,
  RedirectRequest,
} from '@azure/msal-browser';

const host = `${window.location.protocol}//${window.location.host}`;

const config: Configuration = {
  auth: {
    authority:
      'https://login.microsoftonline.com/df46289b-c1b6-410a-9ea6-148b8dd3109e',
    clientId: 'f7dac89e-aa1a-4e71-96ac-13700fab01e5',
    navigateToLoginRequestUrl: false,
    redirectUri: host,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const authProvider = new PublicClientApplication(config);

export const authRequest: RedirectRequest = {
  scopes: ['api://df1e1fcb-25b4-4d61-918b-76b6800d9246/access_as_user'],
};
