import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { MainRoutes } from '../../router/router';
import BarChartIcon from '@mui/icons-material/BarChart';
import RocketIcon from '@mui/icons-material/Rocket';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import LogoutIcon from '@mui/icons-material/Logout';
import * as React from 'react';
import { ColorModeContext } from '../../context';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

export const NavigationBar = () => {
  const navigate = useNavigate();
  const handleProjectClick = async () => {
    navigate({
      pathname: MainRoutes.PROJECTS.create({}),
    });
  };

  const handleLogoutClick = () => {
    navigate({
      pathname: MainRoutes.LOGOUT.create({}),
    });
  };

  const handleBackClick = async () => {
    navigate({
      pathname: MainRoutes.HOME.create({}),
    });
  };

  const colorMode = React.useContext(ColorModeContext);
  const theme = useTheme();

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <QueryStatsIcon />
          <Typography
            href="#"
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              ml: 2,
              display: 'flex',
              fontFamily: 'monospace',
              fontStyle: 'italic',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
            onClick={() => handleBackClick()}
          >
            Earlyinsights
          </Typography>
          <Box sx={{ flexGrow: 1, display: 'flex' }}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => handleProjectClick()}
            >
              <BarChartIcon />
              Projects View
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              sx={{ ml: 1 }}
              onClick={colorMode.toggleColorMode}
              color="inherit"
            >
              {theme.palette.primary.main === '#c83771' ? (
                <RocketIcon />
              ) : (
                <RocketLaunchIcon />
              )}
            </IconButton>
            <IconButton
              sx={{ ml: 1 }}
              onClick={() => handleLogoutClick()}
              color="inherit"
            >
              <LogoutIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
