export const baseUrl = window.location
  ? `${window.location.protocol}//${window.location.host}`
  : 'https://localhost:5001';

export const apiUrl = `${baseUrl}/api/v1.0`;

/**
 * Content type headers for json.
 */
export const JSONHeaders = new Headers({ 'Content-Type': 'text/json' });

/**
 * The type of a successfull http request via `fetch`.
 */
export type ResponseSuccess = Readonly<{
  ok: true;
  headers: Headers;
  status: number;
  statusText: string;
  url: string;
  arrayBuffer(): Promise<ArrayBuffer>;
  blob(): Promise<Blob>;
  formData(): Promise<FormData>;
  json(): Promise<any>;
  text(): Promise<string>;
}>;

/**
 * The type of an unsuccessfull http request via `fetch`.
 */
export type ResponseError = Readonly<{
  ok: false;
  headers: Headers;
  status: number;
  statusText: string;
  url: string;
  arrayBuffer(): Promise<ArrayBuffer>;
  blob(): Promise<Blob>;
  formData(): Promise<FormData>;
  json(): Promise<any>;
  text(): Promise<string>;
}>;

/**
 * The type of a http request via `fetch`.
 */
export type RequestResponse = ResponseSuccess | ResponseError;

/**
 * The type of a successfull http request where the
 * json has also been successfully parsed.
 */
export type FetchSuccess<T> = {
  ok: true;
  value: T;
};

/**
 * The type of an unsuccessfull http request with
 * an error type.
 */
export type FetchError<E> = {
  ok: false;
  status: number;
  statusText: string;
  url: string;
  errorValue: E;
};

/**
 * The result of an http request where the answer has been parsed.
 */
export type FetchResult<T, Err> = FetchSuccess<T> | FetchError<Err>;

/**
 * An error as reported by the backend.
 */
export type ServerError = {
  userMessage: string;
  details: string;
};
