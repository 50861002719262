import { route } from 'react-route-type';

export const MainRoutes = {
  HOME: route(''),
  LOGIN: route('login'),
  LOGOUT: route('logout'),
  USER_TIMES: route({
    path: ['times', ':id'],
  }),
  PROJECTS: route('projects'),
};

export const APIRoutes = {
  SYNC: route('sync'),
  SOFTSYNC: route(['sync', 'soft']),
  GETSYNCHTHRESHOLD: route(['sync', 'threshold']),
  DAYSWITHTARGETHOURS: route({
    path: ['users', ':id', 'targetHours'],
  }),
  FAILEDTIMESHEET: route('FailedTimesheet'),
  PROJECT: route({ path: 'projects', query: { dateFrom: '', dateTo: '' } }),
  PROJECT2: route('Project'),
  INVOICE: route('invoices'),
  AVERAGEHOURLYRATECHARGED: route('AverageHourlyRateCharged'),
  AVERAGEHOURLYRATEEARNED: route('AverageHourlyRateEarned'),
  UTILISATION: route('UtilisationLevel'),
  HOURLYMARGINCHARGED: route('HourlyMarginCharged'),
  HOURLYMARGINEARNED: route('HourlyMarginEarned'),
  USER: route({
    path: ['users', ':id'],
  }),
  USER_CALENDAR: route({
    path: ['users', ':id', 'calendar'],
    query: { timeFilter: '', from: '', to: '' },
  }),
  USERS: route('users'),
};
