import * as React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { BaseType } from '../data/base-type';
import { DateTime } from 'luxon';
import { useTheme } from '@mui/material';

const formatAxis = (type: string, value: number) => {
  switch (type) {
    case 'Invoices':
      return Math.round(value / 1000);
    case 'Average Hourly Rate Charged':
      return Math.round(value);
    case 'Average Hourly Rate Earned':
      return Math.round(value);
    case 'Average Utilisation Level':
      return Math.round(value * 100);
    case 'Hourly Margin Charged':
      return Math.round(value);
    case 'Hourly Margin Earned':
      return Math.round(value);
    default:
      return value;
  }
};
const formatTooltip = (type: string, value: number) => {
  switch (type) {
    case 'Invoices':
      return `${value.toLocaleString('de-CH')} CHF`;
    case 'Average Hourly Rate Charged':
      return `${value.toFixed(2)} CHF/h`;
    case 'Average Hourly Rate Earned':
      return `${value.toFixed(2)} CHF/h`;
    case 'hourlyMarginRate':
      return `${value.toFixed(2)} CHF/h`;
    case 'Average Utilisation Level':
      return `${(value * 100).toFixed(2)} %`;
    default:
      return value;
  }
};

const Bar = ({
  data,
  bottomLegend,
  leftLegend,
  title,
}: {
  data: BaseType[];
  bottomLegend: string;
  leftLegend: string;
  title: string;
}) => {
  const theme = useTheme();
  return (
    <ResponsiveBar
      data={data}
      keys={['value']}
      indexBy={'unixDate'}
      valueFormat={(value: number) => `${formatAxis(title, value)}`}
      margin={{ top: 50, right: 30, bottom: 60, left: 70 }}
      padding={0.3}
      colors={theme.palette.primary.main}
      labelTextColor={theme.palette.primary.contrastText}
      axisBottom={{
        legend: bottomLegend,
        format: (value) =>
          DateTime.fromMillis(Number(value)).toFormat("yy'-Q'q"),
        legendPosition: 'middle',
        legendOffset: 50,
        tickPadding: 10,
        tickRotation: -45,
      }}
      axisLeft={{
        legend: leftLegend,
        format: (value) => formatAxis(title, Number(value)),
        legendPosition: 'middle',
        legendOffset: -50,
      }}
      tooltip={({ indexValue, value, color }) => (
        <div style={{ backgroundColor: 'white', padding: '5px' }}>
          <strong style={{ color }}>
            {`${title} - `}
            {`${DateTime.fromMillis(Number(indexValue)).toFormat(
              "yyyy'-Q'q",
            )} - `}
            {formatTooltip(title, Number(value))}
          </strong>
        </div>
      )}
      theme={{
        axis: {
          legend: {
            text: {
              fill: theme.palette.primary.contrastText,
            },
          },
          ticks: {
            text: {
              fill: theme.palette.primary.contrastText,
            },
            line: {
              fill: theme.palette.primary.contrastText,
            },
          },
        },
      }}
    />
  );
};

export default Bar;
