import * as React from 'react';
import { DateTime, Interval } from 'luxon';
import { ScaleLoader } from 'react-spinners';
import { useParams } from 'react-router-dom';
import { useUser } from '../../data/user/hooks';
import { useUserCalendar, useUserWorkDays } from '../../data/calendar/hooks';
import { SelectedRange } from '../../data/calendar/types';
import { UserCalendar } from './user-calendar';
import { SelectedCalendarRange } from './selected-calendar-range';
import { CalendarDay } from '../../data/calendar/types';
import YearPicker from '../../components/yearPicker/year-picker';

import './user-times.scss';
import '../../styles/general.scss';

import {
  Alert,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  useTheme,
} from '@mui/material';
import { ChartData } from '../../components';
import {
  FailedTimesheet,
  getFailedTimesheet,
} from '../../data/failed-timesheets';
import { authRequest } from '../../data/auth';
import { useAccount, useMsal } from '@azure/msal-react';
import { getSynchThreshold, startSync } from '../../data/user/requests';
import { ChangeEvent, useEffect, useState } from 'react';
import { NavigationBar } from '../../components/navigation/NavigationBar';
type FailedTimesheetState = ChartData<FailedTimesheet>;

export const UserTimes = () => {
  const { instance, accounts } = useMsal();
  const SyncThreshold = 120;
  const account = useAccount(accounts[0]);
  const [failedTimesheetsState, setFailedTimesheetsState] =
    useState<FailedTimesheetState>({
      isLoading: true,
    });
  const [state, setState] = useState({
    workHours: false,
  });
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    setSelectedRange(null);
  };
  const [loading, setLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { user, setUser } = useUser(id!);
  const workDays = useUserWorkDays(id!);
  const { userCalendar, setUserCalendar } = useUserCalendar(
    id!,
    'Daily',
    null,
    null,
  );
  const [timeToSynch, setTimeToSynch] = useState<number | undefined>(undefined);
  useEffect(() => {
    if (timeToSynch === 0) {
      setTimeToSynch(undefined);
    }
    if (!timeToSynch) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeToSynch(timeToSynch - 1);
    }, 1000);
    return () => {
      clearInterval(intervalId);
      setLoading(false);
    };
  }, [timeToSynch]);
  useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent({
          scopes: authRequest.scopes,
          account,
        })
        .then((response) => {
          getSynchThreshold(response.accessToken).then((response) => {
            if (response < SyncThreshold) {
              setTimeToSynch(response);
              setLoading(true);
            }
          });
        });
    }
  }, [account, instance]);
  const [userYears, setUserYears] = useState<number[]>([]);

  const [selectedYear, setSelectedYear] = useState<number>(DateTime.now().year);
  const [selectedRange, setSelectedRange] = useState<SelectedRange | null>(
    null,
  );
  useEffect(() => {
    if (userCalendar.status === 'success') {
      setUserYears(userYearsRange(userCalendar.value));
    }
  }, [userCalendar, setUserYears]);

  useEffect(() => {
    const updater = async () => {
      if (loading) {
        if (account) {
          await instance
            .acquireTokenSilent({
              scopes: authRequest.scopes,
              account,
            })
            .then(async (response) => {
              await startSync(response.accessToken).then((response) => {
                setTimeToSynch(response);
                if (response >= SyncThreshold - 15) {
                  userCalendar.status === 'success'
                    ? setUserCalendar({
                        status: 'loading-with-value',
                        value: userCalendar.value,
                      })
                    : setUserCalendar({
                        status: 'loading',
                      });
                  user.status === 'success'
                    ? setUser({
                        status: 'loading-with-value',
                        value: user.value,
                      })
                    : setUser({
                        status: 'loading',
                      });

                  setFailedTimesheetsState({ isLoading: true });
                }
                if (selectedRange !== null) {
                  const temporarySelectedRange = selectedRange;
                  setSelectedRange(null);
                  setTimeout(
                    () => setSelectedRange(temporarySelectedRange),
                    200,
                  );
                }
                setLoading(false);
              });
            });
        }
      }
    };

    updater();
  }, [
    setUserCalendar,
    loading,
    account,
    instance,
    userCalendar,
    user,
    setUser,
    selectedRange,
    setSelectedRange,
  ]);
  useEffect(() => {
    if (failedTimesheetsState.isLoading && account) {
      instance
        .acquireTokenSilent({
          scopes: authRequest.scopes,
          account,
        })
        .then((response) => {
          getFailedTimesheet(response.accessToken).then((failedTimesheets) => {
            setFailedTimesheetsState({
              isLoading: false,
              data: failedTimesheets,
            });
          });
        });
    }
  });

  const handleSynchClick = () => {
    setLoading(true);
  };

  const theme = useTheme();

  const appHeader = (
    <header className="app-header">
      <NavigationBar />
    </header>
  );
  switch (user.status) {
    case 'loading': {
      return (
        <>
          {appHeader}
          <div
            style={{
              marginTop: '60px',
            }}
          >
            <div
              style={{
                margin: 'auto',
                width: '200px',
              }}
            >
              <ScaleLoader
                color={theme.palette.primary.main}
                height="100px"
                width="20px"
              />
            </div>
          </div>
        </>
      );
    }
    case 'error': {
      return <>Error Loading User</>;
    }
    case 'success':
    case 'loading-with-value': {
      const userValue = user.value;
      return (
        <div>
          {appHeader}
          <Container maxWidth="xl">
            <div className="user-times">
              <div>
                <h1>
                  {userValue.firstname} {userValue.lastname}
                  <Button
                    sx={{
                      ml: 2,
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      '&:hover': {
                        backgroundColor: theme.palette.primary.dark,
                      },
                      '&.Mui-disabled': {
                        backgroundColor: theme.palette.primary.light,
                      },
                    }}
                    className="user-base-button"
                    variant="contained"
                    size="small"
                    disabled={loading || !!timeToSynch}
                    disableElevation
                    onClick={() => handleSynchClick()}
                  >
                    {loading && !!!timeToSynch ? 'Synching' : 'Synch'}
                    {timeToSynch && timeToSynch > 0 ? ' in ' + timeToSynch : ''}
                  </Button>
                </h1>
              </div>
              <Stack
                sx={{ width: '1024px', margin: 'auto', marginBottom: '40px' }}
                alignItems="left"
                spacing={1}
              >
                {!failedTimesheetsState.isLoading &&
                  failedTimesheetsState.data
                    .filter((e) => e.userId === userValue.id)
                    .map((failedTimesheet: FailedTimesheet, index) => {
                      return (
                        <Alert
                          key={index}
                          variant="filled"
                          severity="warning"
                          sx={{ background: '#FCD838', color: 'black' }}
                          action={
                            <Button
                              sx={{ background: '#FCD838', color: 'black' }}
                              size="small"
                              target="_blank"
                              variant="text"
                              href={`https://office.bexio.com/index.php/monitoring/show/id/${failedTimesheet.id}`}
                            >
                              <strong>Show in Bexio</strong>
                            </Button>
                          }
                        >
                          Hey {userValue.firstname} please fix your entry from{' '}
                          {new Date(failedTimesheet.date).toDateString()}!
                        </Alert>
                      );
                    })}
              </Stack>
              <div className="user-times-overview">
                <div className="user-times-vacations">
                  <h2>Vacations</h2>
                  <table>
                    <thead>
                      <tr>
                        <th>Year</th>
                        <th>Available</th>
                        <th>Used</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userValue.holidays.map((holiday) => {
                        return (
                          <tr key={holiday.year}>
                            <td>{holiday.year}</td>
                            <td>{holiday.holidays.toFixed(2)}</td>
                            <td>{holiday.usedHolidays.toFixed(2)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="user-times-work-life">
                  <h2>Work Life Balance</h2>
                  <table>
                    <tbody>
                      <tr>
                        <th>Total Overtime</th>
                        <td>{userValue.overTime}</td>
                      </tr>
                      <tr>
                        <th>Available Vacations</th>
                        <td>{userValue.availableHolidays.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <Grid
                className="filter-grid"
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.workHours}
                      onChange={handleChange}
                      name="workHours"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  }
                  label="Work Days"
                />
                {!state.workHours && (
                  <FormControlLabel
                    control={
                      <YearPicker
                        value={selectedYear}
                        handleChange={(date) => {
                          setSelectedYear(date);
                        }}
                        dates={userYears}
                      />
                    }
                    label="Selected Year&ensp;"
                    labelPlacement="start"
                  />
                )}
              </Grid>
              <div className="user-times-calendars">
                <UserCalendar
                  calendarDataResult={state.workHours ? workDays : userCalendar}
                  onSelectDay={(day) => {
                    setSelectedRange({
                      selectedDay: day,
                      week: Interval.fromDateTimes(
                        day.set({ weekday: 1 }),
                        day.set({ weekday: 7 }),
                      ),
                    });
                  }}
                  workDays={state.workHours}
                  selectedYear={selectedYear}
                />
              </div>
              <div>
                {selectedRange ? (
                  <SelectedCalendarRange
                    userId={id!}
                    selectedRange={selectedRange}
                    workHours={state.workHours}
                  />
                ) : (
                  <>No date selected yet</>
                )}
              </div>
            </div>
          </Container>
        </div>
      );
    }
  }
};

const userYearsRange = (days: Array<CalendarDay>): number[] => {
  const range = (start: number, end: number) =>
    Array.from(Array(end - start + 1).keys()).map((x) => x + start);

  const dateTimes = (days as any).map((day: any) => day.date);
  const startDate = DateTime.min.apply(null, dateTimes).year;
  const endDate = DateTime.max.apply(null, dateTimes).year;

  return range(startDate, endDate);
};
