import * as React from 'react';
import ProjectsInfo from '../../components/projects-info/projects-info';
import '../../styles/general.scss';
import { NavigationBar } from '../../components/navigation/NavigationBar';

export const Projects = (): JSX.Element => {
  return (
    <div className="app">
      <header className="app-header">
        <NavigationBar />
      </header>
      <ProjectsInfo />
    </div>
  );
};
