import { apiUrl } from './base';
import { APIRoutes } from '../router/router';
import { makeAuthHeaders } from './fetch/requests';
import { DateTime } from 'luxon';

export type Project = {
  id: number;
  name: string;
  startDate: string;
  contactId: number;
  internalHours: number;
  billableHours: number;
  nonBillableHours: number;
  vacationHours: number;
};

export type Projects = Project[];

export type ProjectExternal = Project;

export function fromAPI(externalProject: ProjectExternal): Project {
  return externalProject;
}

export const getProject = async (
  bearerToken: string,
  dateFrom: DateTime,
  dateTo: DateTime,
): Promise<Project[]> => {
  const headers = makeAuthHeaders(bearerToken);
  const requestData = await fetch(
    new Request(
      `${apiUrl}${APIRoutes.PROJECT.create({
        query: {
          dateFrom: dateFrom.toISODate(),
          dateTo: dateTo.toISODate(),
        },
      })}`,
      {
        headers,
      },
    ),
  );
  const jsonData = await requestData.json();
  return jsonData;
};
