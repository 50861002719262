import { apiUrl } from './base';
import { APIRoutes } from '../router/router';
import { makeAuthHeaders } from './fetch/requests';
import {
  BaseType,
  BaseTypeExternal,
  formattedDateToUnixDate,
} from './base-type';

export type AverageHourlyRateCharged = BaseType & {
  totalNetSumInvoices: number;
  totalBillableAndNonBillableHours: number;
  averageHourlyRate: number;
};

export type AverageHourlyRatesCharged = AverageHourlyRateCharged[];

export type AverageHourlyRateChargedExternal = BaseTypeExternal & {
  totalNetSumInvoices: number;
  totalBillableAndNonBillableHours: number;
  averageHourlyRate: number;
};

export function fromAPI(
  externalAverageHourlyRateCharged: AverageHourlyRateChargedExternal,
): BaseType {
  return {
    unixDate: formattedDateToUnixDate(
      externalAverageHourlyRateCharged.formattedDate,
    ),
    value: externalAverageHourlyRateCharged.averageHourlyRate,
  };
}

export const getAverageHourlyRatesCharged = async (
  bearerToken: string,
): Promise<AverageHourlyRatesCharged> => {
  const headers = makeAuthHeaders(bearerToken);
  const requestData = await fetch(
    new Request(`${apiUrl}${APIRoutes.AVERAGEHOURLYRATECHARGED.create({})}`, {
      headers,
    }),
  );
  const jsonData = await requestData.json();
  return jsonData.map(fromAPI);
};
