import { APIRoutes } from '../../router/router';
import { jsonGetAuth, makeAuthHeaders } from '../fetch/requests';
import { RequestResult } from '../fetch/result';
import { User } from './types';
import { apiUrl } from '../base';

export async function getUser(
  bearerToken: string,
  userId: string,
): Promise<RequestResult<User>> {
  return jsonGetAuth(
    bearerToken,
    apiUrl + APIRoutes.USER.create({ id: userId }),
    (json: unknown) => {
      return { errorType: 'none', value: json as User };
    },
  );
}

export async function getUsers(
  bearerToken: string,
): Promise<RequestResult<Array<User>>> {
  return jsonGetAuth(
    bearerToken,
    apiUrl + APIRoutes.USERS.create({}),
    (json: unknown) => {
      return { errorType: 'none', value: json as Array<User> };
    },
  );
}

export const startSync = async (bearerToken: string): Promise<number> => {
  const headers = makeAuthHeaders(bearerToken);
  const requestData = await fetch(
    new Request(apiUrl + APIRoutes.SOFTSYNC.create({}), {
      method: 'POST',
      headers,
    }),
  );
  const jsonData = await requestData.json();
  return jsonData;
};
export const getSynchThreshold = async (
  bearerToken: string,
): Promise<number> => {
  const headers = makeAuthHeaders(bearerToken);
  const requestData = await fetch(
    new Request(apiUrl + APIRoutes.GETSYNCHTHRESHOLD.create({}), {
      method: 'GET',
      headers,
    }),
  );
  const jsonData = await requestData.json();
  return jsonData;
};
