import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { RequestResult } from '../fetch/result';
import { CalendarDay, TimeFilter } from './types';
import { getUserCalendar, getUserWorkDays } from './requests';
import { useAccount, useMsal } from '@azure/msal-react';
import { authRequest } from '../auth';
import { DayWithTargetHours } from './types';

export function useUserCalendar(
  userId: string,
  timeFilter: TimeFilter,
  from: DateTime | null,
  to: DateTime | null,
): {
  userCalendar: RequestResult<Array<CalendarDay>>;
  setUserCalendar: React.Dispatch<
    React.SetStateAction<RequestResult<CalendarDay[]>>
  >;
} {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [userCalendar, setUserCalendar] = useState<
    RequestResult<Array<CalendarDay>>
  >({
    status: 'loading',
  });
  useEffect(() => {
    if (account && userCalendar.status !== 'success') {
      instance
        .acquireTokenSilent({
          scopes: authRequest.scopes,
          account,
        })
        .then((response) => {
          getUserCalendar(
            response.accessToken,
            userId,
            timeFilter,
            from,
            to,
          ).then(setUserCalendar);
        });
    }
  }, [userId, account, instance, from, to, timeFilter, userCalendar.status]);

  return { userCalendar, setUserCalendar };
}
export function useUserWorkDays(
  userId: string,
): RequestResult<Array<DayWithTargetHours>> {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [user, setUser] = useState<RequestResult<Array<DayWithTargetHours>>>({
    status: 'loading',
  });

  useEffect(() => {
    setUser({ status: 'loading' });
    if (account) {
      instance
        .acquireTokenSilent({
          scopes: authRequest.scopes,
          account,
        })
        .then(async (response) => {
          getUserWorkDays(response.accessToken, userId).then(setUser);
        });
    }
  }, [userId, account, instance]);

  return user;
}
