import { DateTime } from 'luxon';

export type BaseTypeExternal = {
  formattedDate: string;
};

export type BaseType = {
  unixDate: number;
  value: number;
};

export const formattedDateToUnixDate = (formattedDate: string) =>
  DateTime.fromFormat(formattedDate.replace('Q', ''), 'yyyy-q').toMillis();
