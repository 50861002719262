import { DateTime } from 'luxon';
import { APIRoutes } from '../../router/router';
import { jsonGetAuth } from '../fetch/requests';
import { RequestResult } from '../fetch/result';
import { apiUrl } from '../base';
import { calendarDayFromApi, workDayFromApi } from './conversion';
import { TimeFilter, CalendarDay, DayWithTargetHours } from './types';

export async function getUserCalendar(
  bearerToken: string,
  userId: string,
  timeFilter: TimeFilter,
  from: DateTime | null,
  to: DateTime | null,
): Promise<RequestResult<Array<CalendarDay>>> {
  return jsonGetAuth(
    bearerToken,
    apiUrl +
      APIRoutes.USER_CALENDAR.create({
        id: userId,
        query: {
          timeFilter,
          from: from ? from.toISODate() : undefined,
          to: to ? to.toISODate() : undefined,
        },
      }),
    calendarDayFromApi,
  );
}
export async function getUserWorkDays(
  bearerToken: string,
  userId: string,
): Promise<RequestResult<Array<DayWithTargetHours>>> {
  return jsonGetAuth(
    bearerToken,
    apiUrl +
      APIRoutes.DAYSWITHTARGETHOURS.create({
        id: userId,
      }),
    workDayFromApi,
  );
}
