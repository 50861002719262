import { useEffect, useState } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { RequestResult } from '../fetch/result';
import { User } from './types';
import { getUser, getUsers } from './requests';
import { authRequest } from '../auth';

export function useUser(userId: string): {
  user: RequestResult<User>;
  setUser: React.Dispatch<React.SetStateAction<RequestResult<User>>>;
} {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [user, setUser] = useState<RequestResult<User>>({ status: 'loading' });
  useEffect(() => {
    if (account && user.status !== 'success') {
      instance
        .acquireTokenSilent({
          scopes: authRequest.scopes,
          account,
        })
        .then((response) => {
          getUser(response.accessToken, userId).then(setUser);
        })
        .catch((error) => {
          console.log(error);
          instance.loginRedirect({
            scopes: authRequest.scopes,
          });
        });
    }
  }, [userId, account, instance, user]);

  return { user, setUser };
}

export function useUsers(): RequestResult<Array<User>> {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [users, setUsers] = useState<RequestResult<Array<User>>>({
    status: 'loading',
  });

  useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent({
          scopes: authRequest.scopes,
          account,
        })
        .then((response) => {
          getUsers(response.accessToken).then(setUsers);
        })
        .catch((error) => {
          console.log(error);
          instance.loginRedirect({
            scopes: authRequest.scopes,
          });
        });
    }
  }, [account, instance]);

  return users;
}
