const utilizationLevel =
  'It is the level at which you use your resources. This KPI tells us the % of the total number of charged working hours by: dividing charged hours with the total number of hours worked (including extra hours and non-billable hours).';
const AHRC =
  'It is the average rate you charge to the clients. This KPI tells you the average price at which you charge your customers: dividing charged price with the total number of hours charged.';
const AHRE =
  'It is the average rate you earn per worked hour (independently if internal or not). This KPI tells you taking into account the Utilisation level, what is the neto hourly rate.';
const HourlyMarginCharged =
  'It is the margin per hour for each employee as charged to the customers: Average Hourly Rate Charged divided by hourly wage';
const HourlyMarginEarned =
  'It is the margin per hour for each employee taking into account non billed hours: Average Hourly Rate Earned divided by hourly wage';
const Invoices =
  'This is how much we invoice, or otherwise called the top line. It is important to keep a close eye on it since it drives most of all other KPIs.';

function getKPIDescription(title: string) {
  switch (title) {
    case 'Invoices':
      return Invoices;
    case 'Average Hourly Rate Charged':
      return AHRC;
    case 'Average Hourly Rate Earned':
      return AHRE;
    case 'Average Utilisation Level':
      return utilizationLevel;
    case 'Hourly Margin Charged':
      return HourlyMarginCharged;
    case 'Hourly Margin Earned':
      return HourlyMarginEarned;
    default:
      return 'ERROR';
  }
}
export default getKPIDescription;
