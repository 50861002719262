import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './year-picker.scss';
type YearPickerProps = {
  value: number;
  handleChange: (value: number) => void;
  dates: number[];
};

const YearPicker = ({ value, handleChange, dates }: YearPickerProps) => (
  <div>
    <FormControl variant="outlined">
      <Select
        className="year-picker"
        native
        value={value}
        onChange={(event) => handleChange(Number(event.target.value))}
      >
        {dates.map((date: number) => (
          <option
            key={date.toString()}
            style={{ backgroundColor: '#393d41' }}
            value={date}
          >
            {date}
          </option>
        ))}
      </Select>
    </FormControl>
  </div>
);

export default YearPicker;
