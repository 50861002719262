import { apiUrl } from './base';
import { APIRoutes } from '../router/router';
import { makeAuthHeaders } from './fetch/requests';

export type FailedTimesheet = {
  id: string;
  userId: number;
  userName: string;
  statusName: string;
  date: Date;
  duration: string;
};

export type FailedTimesheets = FailedTimesheet[];

export type FailedTimesheetExternal = FailedTimesheet;

export function fromAPI(
  externalFailedTimesheet: FailedTimesheetExternal,
): FailedTimesheet {
  return externalFailedTimesheet;
}

export const getFailedTimesheet = async (
  bearerToken: string,
): Promise<FailedTimesheets> => {
  const headers = makeAuthHeaders(bearerToken);
  const requestData = await fetch(
    new Request(`${apiUrl}${APIRoutes.FAILEDTIMESHEET.create({})}`, {
      headers,
    }),
  );
  const jsonData = await requestData.json();
  return jsonData.map(fromAPI);
};
