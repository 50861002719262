import { RequestResult } from './result';
import { Conversion } from './conversion';

//------------------------------------------------------------------------------
// Requests
//------------------------------------------------------------------------------

function requestInfoToUrlAndMethod(request: RequestInfo): {
  url: string;
  method: string;
} {
  return typeof request === 'string'
    ? { method: 'GET', url: request }
    : { method: request.method, url: request.url };
}

export async function jsonReq<T>(
  request: RequestInfo,
  fromApi: (requestJSON: unknown) => Conversion<T>,
): Promise<RequestResult<T>> {
  try {
    const response = await fetch(request);
    if (response.status === 200) {
      try {
        const value = await response.json();
        const convertedValue = fromApi(value);
        if (convertedValue.errorType === 'none') {
          return { status: 'success', value: convertedValue.value };
        } else {
          return {
            status: 'error',
            errorValue: convertedValue,
          };
        }
      } catch {
        // if we got here there was an error while parsing the resulting json
        try {
          const bodyText = await response.text();
          return {
            status: 'error',
            errorValue: {
              errorType: 'json-parsing-error',
              jsonWithError: bodyText,
            },
          };
        } catch {
          // if we got here then the server never returned a body
          return {
            status: 'error',
            errorValue: {
              errorType: 'missing-body-error',
            },
          };
        }
      }
    } else {
      // the response status wasn't `200`
      const requestInfo = requestInfoToUrlAndMethod(request);
      try {
        const errorValue = await response.json();
        return {
          status: 'error',
          errorValue: {
            errorType: 'server-error',
            status: response.status,
            statusText: response.statusText,
            url: requestInfo.url,
            method: requestInfo.method,
            userMessage: errorValue.userMessage,
            details: errorValue.details,
          },
        };
      } catch {
        // an error happened, but we couldn't parse the JSON
        return {
          status: 'error',
          errorValue: {
            errorType: 'error-parsing-error',
          },
        };
      }
    }
  } catch {
    // if we got here we have a connection error
    const requestInfo = requestInfoToUrlAndMethod(request);
    return {
      status: 'error',
      errorValue: {
        errorType: 'connection-error',
        url: requestInfo.url,
        method: requestInfo.method,
      },
    };
  }
}

export async function jsonGet<T>(
  url: string,
  fromApi: (requestJSON: unknown) => Conversion<T>,
): Promise<RequestResult<T>> {
  return jsonReq(url, fromApi);
}

export function makeAuthHeaders(bearerToken: string): Record<string, string> {
  return {
    Authorization: `Bearer ${bearerToken}`,
    Accept: 'application/json',
    'Content-Type': ' application/json',
  };
}

export async function jsonGetAuth<T>(
  bearerToken: string,
  url: string,
  fromApi: (requestJSON: unknown) => Conversion<T>,
) {
  const headers = makeAuthHeaders(bearerToken);
  return jsonReq(new Request(url, { headers }), fromApi);
}
