import { BasicTooltip } from '@nivo/tooltip';
import { CalendarTooltipProps } from './types';
import { memo } from 'react';
import React from 'react';

export const CalendarTooltip = memo(
  ({ value, day, color }: CalendarTooltipProps) => {
    if (value === undefined || isNaN(Number(value))) {
      return (
        <BasicTooltip id={day} value={''} color={color} enableChip={true} />
      );
    } else {
      return (
        <BasicTooltip id={day} value={value} color={color} enableChip={true} />
      );
    }
  },
);
