import * as React from 'react';
import { Link } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { useUsers } from '../../data/user/hooks';
import { MainRoutes } from '../../router/router';
import { RequestResult } from '../../data/fetch/result';
import { User } from '../../data/user/types';
import './users.scss';
import Avatar from '@mui/material/Avatar';
import { useMemo, useCallback } from 'react';
import { useTheme } from '@mui/material';

function randomColor() {
  let hex = Math.floor(Math.random() * 0xffffff);
  let color = '#' + hex.toString(16);

  return color;
}

export const Users = () => {
  const usersResult: RequestResult<Array<User>> = useUsers();
  const theme = useTheme();

  const renderUsers = useCallback(() => {
    switch (usersResult.status) {
      case 'loading': {
        return <ScaleLoader color={theme.palette.primary.main} />;
      }
      case 'error': {
        return <>Error Loading User</>;
      }
      case 'loading-with-value':
      case 'success': {
        const users = usersResult.value;
        return (
          <ul className="users-users-list">
            {users.map((user) => {
              const userName = `${user.firstname} ${user.lastname}`;
              const hourUpdateStatusColor =
                user.hourUpdateStatus === 'ok'
                  ? theme.palette.success.light
                  : user.hourUpdateStatus === 'delayed'
                  ? theme.palette.error.light
                  : '#90a4ae';
              return (
                <li key={user.id.toString()}>
                  <div>
                    <Link
                      to={MainRoutes.USER_TIMES.create({
                        id: user.id.toString(),
                      })}
                    >
                      <div>
                        <Avatar
                          className="user-avatar"
                          variant="square"
                          alt={userName}
                          src={`people/${user.id}.jpg`}
                          style={{
                            backgroundColor: randomColor(),
                          }}
                          sx={{
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '8px',
                            borderBottomColor: hourUpdateStatusColor,
                          }}
                        />
                        <div className="users-user-name">{userName}</div>
                      </div>
                    </Link>
                  </div>
                </li>
              );
            })}
          </ul>
        );
      }
    }
  }, [usersResult, theme]);

  return useMemo(() => renderUsers(), [renderUsers]);
};
