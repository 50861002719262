import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import * as React from 'react';
import { ScaleLoader } from 'react-spinners';
import Bar from '../bar';
import getKPIDescription from '../../data/kpi-descripitions';
import './chart.scss';
import { BaseType } from '../../data/base-type';
import { useTheme } from '@mui/material';

export type ChartData<T> =
  | {
      isLoading: true;
    }
  | {
      isLoading: false;
      data: T[];
    };

export type DateRange = {
  from: number;
  to: number;
};

export type DateRangeAsync =
  | {
      isLoading: true;
    }
  | {
      isLoading: false;
      range: DateRange;
    };

type ChartProps = {
  chartData: ChartData<BaseType>;
  title: string;
  bottomLegend: string;
  leftLegend: string;
  selectedDateRange: DateRangeAsync;
};

const Chart = ({
  chartData,
  title,
  bottomLegend,
  leftLegend,
  selectedDateRange,
}: ChartProps) => {
  const theme = useTheme();

  return (
    <div className="chart">
      {chartData.isLoading || selectedDateRange.isLoading ? (
        <ScaleLoader
          color={theme.palette.primary.main}
          height="100px"
          width="20px"
        />
      ) : chartData.data && chartData.data.length > 0 ? (
        <Bar
          data={chartData.data.filter(
            (d) =>
              d.unixDate >= selectedDateRange.range.from &&
              d.unixDate <= selectedDateRange.range.to,
          )}
          bottomLegend={bottomLegend}
          leftLegend={leftLegend}
          title={title}
        />
      ) : (
        <h3 className="error-message">No data</h3>
      )}{' '}
      <div className="chart-title-container">
        <h2 className="chart-title">{title}</h2>
        <Tooltip className="tooltip-info" title={getKPIDescription(title)}>
          <InfoOutlinedIcon style={{ fill: theme.palette.primary.main }} />
        </Tooltip>
      </div>
    </div>
  );
};

export default Chart;
